define("discourse/plugins/discourse-user-links/discourse/components/user-link-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/utils", "discourse/lib/plugin-api"], function (_exports, _component, _tracking, _object, _utils, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getMatches(string, regex, index) {
    if ((0, _utils.isEmpty)(string)) {
      return [];
    }
    index || (index = 1);
    var matches = [];
    var match;
    while (match = regex.exec(string)) {
      matches.push(match[index]);
    }
    return matches;
  }
  function getTopicIdFromString(string) {
    const match = getMatches(string, /\/t\/[^\/]*\/(\d+)[\/]?/g);
    if (match.length > 0) {
      return match[0];
    }
    return string;
  }
  class UserLinkSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modifiedUsername", [_tracking.tracked], function () {
      return '';
    }))();
    #modifiedUsername = (() => (dt7948.i(this, "modifiedUsername"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "model", [_tracking.tracked], function () {
      return null;
    }))();
    #model = (() => (dt7948.i(this, "model"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_tracking.tracked], function () {
      return null;
    }))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "collectionId", [_tracking.tracked], function () {
      return null;
    }))();
    #collectionId = (() => (dt7948.i(this, "collectionId"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "wtbId", [_tracking.tracked], function () {
      return null;
    }))();
    #wtbId = (() => (dt7948.i(this, "wtbId"), void 0))();
    constructor() {
      super(...arguments);
      this.model = this.args.model;
      this.siteSettings = this.args.siteSettings;
      this.collectionId = getTopicIdFromString(this.model.custom_fields.collection_thread);
      this.wtbId = getTopicIdFromString(this.model.custom_fields.wtb_thread);
    }
    getCollectionTopicId(event) {
      let string = event.target.value;
      this.collectionId = getTopicIdFromString(string);
    }
    static #_6 = (() => dt7948.n(this.prototype, "getCollectionTopicId", [_object.action]))();
    getWtbTopicId(event) {
      let string = event.target.value;
      this.wtbId = getTopicIdFromString(string);
    }
    static #_7 = (() => dt7948.n(this.prototype, "getWtbTopicId", [_object.action]))();
  }
  _exports.default = UserLinkSettings;
});